import React, { useRef, useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DiscoverStores from '../../../components/DiscoverStores/DiscoverStores';
import HomeBanner from '../../../components/HomeBanner/HomeBanner';
import HomeColasInnov from '../../../components/HomeColasInnov/HomeColasInnov';
import HomeJoinUs from '../../../components/HomeJoinUs/HomeJoinUs';
import HomeNews from '../../../components/HomeNews/HomeNews';
import HomeOurActivities from '../../../components/HomeOurActivities/HomeOurActivities';
import CardSection from '../../../components/ContentType/CardSection/CardSection'
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import useMedias from '../../../hooks/useMedias';
import { getSectionBG, tradDroiteGauche } from '../../../Utils/Utils';

let classNames = require('classnames');
const IndexPage = ({ data, pageContext }) => {
  const intl = useIntl();

  const bannerRef = useRef(null);
  const { getImage } = useMedias();

  const [stickyTop, setStickyTop] = useState(0);
  const [stickyFixed, setStickyFixed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [stickyVisible, setStickyVisible] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);

  const { locale } = pageContext;
  const homeNodesData = data.homeNodesData?.edges[0];
  const imagesArray = data.allImages.edges;
  const videosArray = data.allVideos.edges;
  const promotedNews = data.promotedNews.edges;
  const eventBannerData = data.eventBannerData?.edges[0]?.node?.relationships?.field_blocs.filter((block) => block.__typename === "block_content__block_banniere_evenementielle");
  const metaTags = data.homeNodesData?.edges[0].node.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  useEffect(() => {
    const is_event_banner = document.querySelector('#header_container').classList.contains('header_with_event_banner');
    setHeaderHeight(window.innerWidth > 1023 ? 110 + (is_event_banner ? 160 : 0) : 61);
    setFooterHeight(document.querySelector('#footer').offsetHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();
    setStickyVisible(true);
    return () => {
      document.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerHeight]);

  const handleResize = () => {
    const is_event_banner = document.querySelector('#header_container').classList.contains('header_with_event_banner');
    setHeaderHeight(window.innerWidth > 1023 ? 110 + (is_event_banner ? 160 : 0) : 61);
    if (!bannerRef.current) {
      return false;
    }
    if (
      bannerRef.current.offsetHeight + headerHeight >
      window.innerHeight
    ) {
      if (
        scrollTop + window.innerHeight >
        bannerRef.current.offsetHeight + headerHeight + 230
      ) {
        setStickyTop(bannerRef.current.offsetHeight - headerHeight + 100);
        setStickyFixed(true);
      } else {
        setStickyTop(bannerRef.current.offsetHeight);
        setStickyFixed(false);
      }
    } else {
      setStickyTop(bannerRef.current.offsetHeight + headerHeight);
      setStickyFixed(true);
    }
  };

  const handleScroll = () => {
    setScrollTop(window.pageYOffset);
  };

  useEffect(() => {
    setPageScrolled(scrollTop > headerHeight);
    if (
      bannerRef?.current?.offsetHeight + headerHeight >
      window.innerHeight
    ) {
      if (
        scrollTop + window.innerHeight >
        bannerRef.current.offsetHeight + headerHeight + 230
      ) {
        setStickyTop(bannerRef.current.offsetHeight - headerHeight + 100);
        setStickyFixed(true);
      } else {
        setStickyTop(bannerRef.current.offsetHeight);
        setStickyFixed(false);
      }
    }
    if ((window.innerHeight + window.scrollY + (footerHeight / 2)) > document.body.offsetHeight) {
      setStickyVisible(false);
    } else {
      setStickyVisible(true);
    }

  }, [scrollTop]);

  return (
    <Layout eventBannerData={eventBannerData ? eventBannerData[0] : null} imagesArray={imagesArray}>
      <Seo
        title={homeNodesData?.node?.field_metatag?.title ? homeNodesData?.node?.field_metatag?.title : metaTitle}
        description={homeNodesData?.node?.field_metatag?.description ? homeNodesData?.node?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'zero_bottom_padding')}>
        {homeNodesData?.node?.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__main_banner':
              return (
                <div className="home_banner" ref={bannerRef} key={i}>
                  <HomeBanner
                    homeBannerNode={block}
                    imagesArray={imagesArray}
                    videosArray={videosArray}
                  >
                    <div className="wrapper_page">
                      <Breadcrumb
                        page_banner={true}
                        data={{
                          currentPage: {
                            title: intl.formatMessage({
                              id: 'common.goto.home',
                            }),
                            url: '/',
                          },
                          locale,
                        }}
                      />
                      <div className="intro">
                        <QuoteMark
                          text={block.field_title.processed}
                          tag="h1"
                          lang={locale}
                        />
                        {
                          block?.body?.processed &&
                          <div
                            dangerouslySetInnerHTML={{
                              __html: block.body.processed,
                            }}
                          ></div>
                        }

                      </div>
                    </div>
                  </HomeBanner>
                </div>
              );

            case 'block_content__block_innovation':
              return (
                <section
                  className={classNames(
                    'section_colas_innov',
                    'section_content',
                    block.field_backgroud_color != 'ice' &&
                      block.field_backgroud_color != 'navy'
                      ? 'index_top'
                      : null
                  )}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                  >
                    <HomeColasInnov
                      innovationNode={block}
                      imagesArray={imagesArray}
                      key={i}
                    />
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_slider_nos_activites':
              return (
                <HomeOurActivities
                  activitiesAllDetails={block}
                  imagesArray={imagesArray}
                  key={i}
                />
              );

            case 'block_content__block_actualites':
              return (
                <HomeNews
                  newsNode={block}
                  topNews={promotedNews}
                  imagesArray={imagesArray}
                  documentsArray={data.allDocuments?.edges}
                  key={i}
                />
              );
            case 'block_content__basic':
              return (
                <section className='section_content'>
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                  >
                    <div className="section_content">
                      <div className="wrapper_page">
                        <TextVisuelCTATwoCol
                          title={{
                            titleSize: 'h2',
                            titleText: block.field_title?.processed,
                            subtitle: block?.field_subtitle?.processed,
                          }}
                          extremeLeft={true}
                          visuel={getImage(
                            imagesArray,
                            block?.field_image?.drupal_internal__target_id
                          )}
                          alignLeft={false}
                          orderInverse={false}
                          alignTop={false}
                          text={block.body?.processed}
                          cta={{
                            ctaText: block.field_link?.title,
                            ctaLink: block.field_link?.url,
                          }}
                        />
                      </div>
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_nous_rejoindre':
              return <HomeJoinUs allJoinUsDetails={block} key={i} />;

            default:
              break;
          }
        })}
        <div
          className={classNames('discover_store_sticky', {
            hide: !stickyVisible,
          })}
          style={{
            top: `calc(${stickyTop}px)`,
            position: stickyFixed ? 'fixed' : 'absolute',
          }}
        >
          <DiscoverStores sticky={true} smaller={pageScrolled} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query HomePageTemplate(
    $locale: String!
    $imagesID: [Int!]
    $videoID: [Int!]
    $documentsID: [Int!]
  ) {
    homeNodesData: allNodeHomepage(
      filter: { langcode: { eq: $locale }, status: { eq: true } }
    ) {
      edges {
        node {
          status
          langcode
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__main_banner {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_type_de_media
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_mobile_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_video {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_innovation {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_display_rtl
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  relationships {
                    field_slides {
                      field_publish
                      field_title {
                        processed
                      }
                      field_link {
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                      langcode
                    }
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  id
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_link_title
                  field_title {
                    processed
                  }
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__activites {
                          title
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                        ... on node__realisations {
                          title
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                        ... on node__page{
													title
                          path{
														alias
                            langcode
                          }
                          relationships{
														field_image{
															drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_actualites {
                  id
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_link {
                    title
                    uri
                    url
                  }
                  field_title {
                    processed
                  }
                  drupal_internal__id
                }
                ... on block_content__basic{
                  field_title_type
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                    body{
                    processed
                  }
                  
                  field_link{
                    title
                    url
                  }
                  field_image{
                    drupal_internal__target_id
                  }
                  field_alignment
                  field_no_padding
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                }
                ... on block_content__block_nous_rejoindre {
                  body {
                    processed
                  }
                  drupal_internal__id
                  field_offre_text
                  field_link {
                    title
                    url
                  }
                  field_aria_label
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  field_chiffre_offre
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                }
              }
            }
          }
        }
      }
    }

    promotedNews: allNodeArticle(
      filter: {
        promote: { eq: true }
        status: { eq: true }
        langcode: { eq: $locale }
      }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          id
          title
          sticky
          status
          langcode
          promote
          path {
            langcode
            alias
          }
          fields {
            readingTime {
              format
              minutes
              seconds
            }
          }
          relationships {
            field_category {
              drupal_internal__tid
            }
            field_media_pdf {
              drupal_internal__mid
            }
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allVideos: allMediaVideo(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $videoID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_video_file {
              uri {
                url
              }
            }
          }
          langcode
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    eventBannerData: allNodeHomepage(filter: { langcode: { eq: $locale }, status: { eq: true } }){
      edges {
        node {
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_banniere_evenementielle{
									body {
                      processed
                    }
                    field_lien {
                      title
                      url
                    }
                    field_aria_label
                    field_display_logo
                    field_display
                    langcode
                    relationships {
                      field_image {
                        drupal_internal__mid
                        field_media_image{
                          alt
                        }
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default IndexPage;
